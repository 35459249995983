// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-account-js": () => import("./../../../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-exercise-js": () => import("./../../../src/pages/exercise.js" /* webpackChunkName: "component---src-pages-exercise-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-logout-js": () => import("./../../../src/pages/logout.js" /* webpackChunkName: "component---src-pages-logout-js" */),
  "component---src-pages-procedures-js": () => import("./../../../src/pages/procedures.js" /* webpackChunkName: "component---src-pages-procedures-js" */),
  "component---src-pages-progress-js": () => import("./../../../src/pages/progress.js" /* webpackChunkName: "component---src-pages-progress-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-reset-password-confirm-js": () => import("./../../../src/pages/reset_password_confirm.js" /* webpackChunkName: "component---src-pages-reset-password-confirm-js" */),
  "component---src-pages-reset-password-js": () => import("./../../../src/pages/reset_password.js" /* webpackChunkName: "component---src-pages-reset-password-js" */),
  "component---src-pages-waitlist-js": () => import("./../../../src/pages/waitlist.js" /* webpackChunkName: "component---src-pages-waitlist-js" */)
}

